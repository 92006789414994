/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ApolloClient, FieldPolicy, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'isomorphic-unfetch';
import { parseCookies } from 'nookies';

const skipTakePaginationPayments = (keyArgs: string[]): FieldPolicy => {
  return {
    keyArgs: keyArgs,
    merge(existing, incoming, { args }) {
      if (!args) return existing;
      const { skip = 0 } = args;
      const merged = existing ? existing.slice(0) : [];

      const biggest = Math.max(merged.length, skip + incoming.length);
      for (let i = 0; i < biggest; ++i) {
        merged[skip + i] = incoming[i] ?? undefined; // If the incoming at i is undefined, we want to remove the existing
      }
      return merged;
    }
  };
};

// Default pagination merge
const skipTakePagination = (keyArgs: string[]): FieldPolicy => {
  return {
    keyArgs: keyArgs,
    merge(existing, incoming, { args }) {
      if (!args) return existing;
      const { skip = 0 } = args;
      // Slicing is necessary because the existing data is
      // immutable, and frozen in development.
      const merged = existing ? existing.slice(0) : [];
      for (let i = 0; i < incoming.length; ++i) {
        merged[skip + i] = incoming[i];
      }
      return merged;
    }
  };
};

const httpLink = createUploadLink({
  fetch, // Switches between unfetch & node-fetch for client & server.
  uri: `${process.env.API_URL}/graphql`
});

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export default function createApolloClient(ctx: any, initialState: any): ApolloClient<NormalizedCacheObject> {
  if (globalApolloClient && process.browser) {
    return globalApolloClient;
  }

  const { token } = parseCookies(ctx || {});

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-and-network' } },
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
    cache: new InMemoryCache({
      // @ts-ignore
      dataIdFromObject: (object): string | null => {
        if (typeof object === 'undefined') {
          return null;
        }
        return (object.id as string) || null;
      },
      typePolicies: {
        Query: {
          fields: {
            files: skipTakePagination(['where']),
            payments: skipTakePaginationPayments(['where'])
          }
        }
      }
    }).restore(initialState || {})
  });
  globalApolloClient = client;
  return client;
}
